import { create } from 'zustand';
export const usePopup = create((set) => ({
  showPopup: false,
  setShowPopup: (popupHelper) => set(() => ({ showPopup: popupHelper })),
  displayText: 'GPT4',
  setDisplayText: (textHelper) => set(() => ({ displayText: textHelper })),
  fSize: '1.875rem',
  setSize: (sizeHelper) => set(() => ({ fSize: sizeHelper })),
  modalContent: '',
  setContent: (contentHelper) => set(() => ({ modalContent: contentHelper })),
  dropdownContent:
    'Upgrade to the most powerful AI chatbot ever, created just for you. Get full access to all advanced features by clicking Continue below.',
  themeContent: 'Get full access to all advanced features by clicking Continue below.',
  mixPanelTrack: '',
  setMixPanelTrack: (textHelper) => set(() => ({ mixPanelTrack: textHelper })),
  mixPanelClickLocation: '',
  setMixPanelClickLocation: (textHelper) => set(() => ({ mixPanelClickLocation: textHelper })),
}));
export const subscriptionType = create((set) => ({
  type: '',
  setType: (typeHelper) => set(() => ({ type: typeHelper })),
  payment_interval: '',
  setPaymentInterval: (intervalHelper) => set(() => ({ payment_interval: intervalHelper })),
  isPro: false,
  setPro: (proHelper) => set(() => ({ isPro: proHelper })),
  isProMax: false,
  setIsProMax: (proMaxHelper) => set(() => ({ isProMax: proMaxHelper })),
  isEnterprise: false,
  setIsEnterprise: (enterpriseHelper) => set(() => ({ isEnterprise: enterpriseHelper })),
}));

export const usePricingPopup = create((set) => ({
  showPricingPopup: false,
  setShowPricingPopup: (popupHelper) => set(() => ({ showPricingPopup: popupHelper })),
  pricingData: [],
  setPricingData: (dataHelper) => set(() => ({ pricingData: dataHelper })),
}));

export const useCustomData = create((set) => ({
  hasFile: false, // variable to check if has file in conversation history
  setHasFile: (hasFileHelper) => set(() => ({ hasFile: hasFileHelper })),

  // for image upload
  imagesPreview: [],
  setImagesPreview: (value) => set(() => ({ imagesPreview: value })),
  imageUrls: [],
  setImageUrls: (value) => set(() => ({ imageUrls: value })),

}));

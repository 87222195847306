import { useEffect } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { SSE, createPayload, tMessageSchema, tConversationSchema } from 'librechat-data-provider';
import type { TResPlugin, TMessage, TConversation, TSubmission } from 'librechat-data-provider';
import { useAuthContext } from '~/hooks/AuthContext';
import store from '~/store';
import { encodingForModel } from 'js-tiktoken';
import { subscriptionType } from '../components/helper/store.js';

type TResData = {
  plugin: TResPlugin;
  final?: boolean;
  initial?: boolean;
  requestMessage: TMessage;
  responseMessage: TMessage;
  conversation: TConversation;
};

declare global {
  interface Window {
    btutil_setChatUsage: any;
  }
}

export default function useServerStream(submission: TSubmission | null) {
  const setMessages = useSetRecoilState(store.messages);
  const setIsSubmitting = useSetRecoilState(store.isSubmitting);
  const setConversation = useSetRecoilState(store.conversation);
  const resetLatestMessage = useResetRecoilState(store.latestMessage);
  const { token } = useAuthContext();

  const tiktokenModels = [
    'text-davinci-003',
    'text-davinci-002',
    'text-davinci-001',
    'text-curie-001',
    'text-babbage-001',
    'text-ada-001',
    'davinci',
    'curie',
    'babbage',
    'ada',
    'code-davinci-002',
    'code-davinci-001',
    'code-cushman-002',
    'code-cushman-001',
    'davinci-codex',
    'cushman-codex',
    'text-davinci-edit-001',
    'code-davinci-edit-001',
    'text-embedding-ada-002',
    'text-similarity-davinci-001',
    'text-similarity-curie-001',
    'text-similarity-babbage-001',
    'text-similarity-ada-001',
    'text-search-davinci-doc-001',
    'text-search-curie-doc-001',
    'text-search-babbage-doc-001',
    'text-search-ada-doc-001',
    'code-search-babbage-code-001',
    'code-search-ada-code-001',
    'gpt2',
    'gpt-4',
    'gpt-4o',
    'gpt-4o-2024-05-13',
    'gpt-4-0314',
    'gpt-4-32k',
    'gpt-4-32k-0314',
    'gpt-3.5-turbo',
    'gpt-3.5-turbo-0301',
    'gpt-4-1106-preview',
    'gpt-3.5-turbo-0125',
  ];

  const { refreshConversations } = store.useConversations();

  const messageHandler = (data: string, submission: TSubmission) => {
    const {
      messages,
      message,
      plugin,
      plugins,
      initialResponse,
      isRegenerate = false,
    } = submission;

    if (isRegenerate) {
      setMessages([
        ...messages,
        {
          ...initialResponse,
          text: data,
          parentMessageId: message?.overrideParentMessageId ?? null,
          messageId: message?.overrideParentMessageId + '_',
          plugin: plugin ?? null,
          plugins: plugins ?? [],
          submitting: true,
          // unfinished: true
        },
      ]);
    } else {
      setMessages([
        ...messages,
        message,
        {
          ...initialResponse,
          text: data,
          parentMessageId: message?.messageId,
          messageId: message?.messageId + '_',
          plugin: plugin ?? null,
          plugins: plugins ?? [],
          submitting: true,
          // unfinished: true
        },
      ]);
    }
  };

  const cancelHandler = (data: TResData, submission: TSubmission) => {
    const { requestMessage, responseMessage, conversation } = data;
    const { messages, isRegenerate = false } = submission;

    // update the messages
    if (isRegenerate) {
      setMessages([...messages, responseMessage]);
    } else {
      setMessages([...messages, requestMessage, responseMessage]);
    }
    setIsSubmitting(false);

    // refresh title
    if (requestMessage.parentMessageId == '00000000-0000-0000-0000-000000000000') {
      setTimeout(() => {
        refreshConversations();
      }, 2000);

      // in case it takes too long.
      setTimeout(() => {
        refreshConversations();
      }, 5000);
    }

    setConversation((prevState) => ({
      ...prevState,
      ...conversation,
    }));
  };

  const createdHandler = (data: TResData, submission: TSubmission) => {
    const { messages, message, initialResponse, isRegenerate = false } = submission;

    if (isRegenerate) {
      setMessages([
        ...messages,
        {
          ...initialResponse,
          parentMessageId: message?.overrideParentMessageId ?? null,
          messageId: message?.overrideParentMessageId + '_',
          submitting: true,
        },
      ]);
    } else {
      setMessages([
        ...messages,
        message,
        {
          ...initialResponse,
          parentMessageId: message?.messageId,
          messageId: message?.messageId + '_',
          submitting: true,
        },
      ]);
    }

    const { conversationId } = message;
    setConversation((prevState) =>
      tConversationSchema.parse({
        ...prevState,
        conversationId,
      }),
    );
    resetLatestMessage();
  };

  const finalHandler = (data: TResData, submission: TSubmission) => {
    const { requestMessage, responseMessage, conversation } = data;
    const { messages, isRegenerate = false } = submission;

    // update the messages
    if (isRegenerate) {
      setMessages([...messages, responseMessage]);
    } else {
      setMessages([...messages, requestMessage, responseMessage]);
    }
    setIsSubmitting(false);

    // refresh title
    if (requestMessage.parentMessageId == '00000000-0000-0000-0000-000000000000') {
      setTimeout(() => {
        refreshConversations();
      }, 2000);

      // in case it takes too long.
      setTimeout(() => {
        refreshConversations();
      }, 5000);
    }

    setConversation((prevState) => ({
      ...prevState,
      ...conversation,
    }));
  };

  const errorHandler = (data: TResData, submission: TSubmission) => {
    const { messages, message } = submission;

    console.log('Error:', data);
    const errorResponse = tMessageSchema.parse({
      ...data,
      error: true,
      parentMessageId: message?.messageId,
    });
    setIsSubmitting(false);
    setMessages([...messages, message, errorResponse]);
    return;
  };

  const abortConversation = (conversationId = '', submission: TSubmission) => {
    const { endpoint } = submission?.conversation || {};

    fetch(`/api/ask/${endpoint}/abort`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        abortKey: conversationId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        data.conversation.model = submission.conversation.model;
        cancelHandler(data, submission);
      })
      .catch((error) => {
        console.error('Error aborting request');
        console.error(error);
        // errorHandler({ text: 'Error aborting request' }, { ...submission, message });
      });
    return;
  };

  const proState = subscriptionType((state) => state);

  useEffect(() => {
    if (submission === null) {
      return;
    }
    if (Object.keys(submission).length === 0) {
      return;
    }

    let { message } = submission;

    if (!proState.isPro) {
      let endpoint = submission.endpoint;

      if (endpoint === 'openAi' || endpoint === 'gptPlugins' || endpoint === 'Opensource' || endpoint === 'claude' || endpoint === 'dallE') {
        const validModels = [
          'gpt-3.5-turbo-0301',
          'text-davinci-003',
          "meta-llama/Llama-2-70b-chat-hf",
          'zephyr-7B-beta',
          'claude-3-5-sonnet-20240620',
          'dall-e-3'
        ];
        const model = submission.endpointOption.model;

        if (validModels.includes(model)) {
          submission = {
            ...submission,
            endpointOption: {
              ...submission.endpointOption,
              model: model,
            },
          };
        } else {
          submission = {
            ...submission,
            endpointOption: {
              ...submission.endpointOption,
              model: 'gpt-3.5-turbo',
            },
          };
        }
      }
    }

    const { server, payload } = createPayload(submission);

    const events = new SSE(server, {
      payload: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });

    events.onmessage = (e: MessageEvent) => {
      const data = JSON.parse(e.data);

      if (data.final) {
        data.conversation.model = submission.conversation.model;
        const { plugins } = data;
        finalHandler(data, { ...submission, plugins, message });

        if (data.conversation.model === 'dall-e-3') {
          const usage = {};
          let enc = null;
          const model = payload.model;
          
          try {
            
            enc = encodingForModel(tiktokenModels.includes(model) ? model : 'gpt-3.5-turbo');
            usage.prompt_tokens = enc.encode(data.requestMessage.text).length;
            usage.completion_tokens = enc.encode(data.responseMessage.prompt).length;
            usage.total_tokens = usage.prompt_tokens + usage.completion_tokens;
            console.log(usage);
            
          } catch (e) {
            console.log('Error encoding', e);
          }
          try {
            const btutil_setChatUsage = window.btutil_setChatUsage;
            // TOKEN USAGE
            typeof btutil_setChatUsage !== 'undefined' &&
              btutil_setChatUsage('chatbotpro', usage.prompt_tokens, usage.total_tokens, data.conversation.model);
          } catch (e) {
            console.log('error btutil_setChatUsage', e);
          }
        }

        if (data.usage) {
          const usage = {};
          let enc = null;
          const model = payload.model;
          try {
            enc = encodingForModel(tiktokenModels.includes(model) ? model : 'gpt-3.5-turbo');
            usage.prompt_tokens = enc.encode(data.requestMessage.text).length;
            usage.completion_tokens = enc.encode(data.responseMessage.text).length;
            usage.total_tokens = usage.prompt_tokens + usage.completion_tokens;
          } catch (e) {
            console.log('Error encoding', e);
          }
          try {
            const btutil_setChatUsage = window.btutil_setChatUsage;
            // TOKEN USAGE
            typeof btutil_setChatUsage !== 'undefined' &&
              btutil_setChatUsage('chatbotpro', usage.prompt_tokens, usage.total_tokens, data.conversation.model);
          } catch (e) {
            console.log('error btutil_setChatUsage', e);
          }
        }

      }
      if (data.created) {
        message = {
          ...data.message,
          image_urls: message.image_urls,
          overrideParentMessageId: message?.overrideParentMessageId,
        };
        createdHandler(data, { ...submission, message });
      } else {
        const text = data.text || data.response;
        const { plugin, plugins } = data;

        if (data.message) {
          messageHandler(text, { ...submission, plugin, plugins, message });
        }
      }
    };

    events.onopen = () => console.log('connection is opened');

    events.oncancel = () =>
      abortConversation(message?.conversationId ?? submission?.conversationId, submission);

    events.onerror = function (e: MessageEvent) {
      events.close();
      setIsSubmitting(false);
    };

    setIsSubmitting(true);
    events.stream();

    return () => {
      const isCancelled = events.readyState <= 1;
      events.close();
      // setSource(null);
      if (isCancelled) {
        const e = new Event('cancel');
        events.dispatchEvent(e);
      }
      setIsSubmitting(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submission]);
}

import {
  Download,
  LayoutPanelLeft,
  Sparkles,
  HelpCircle,
  Settings as SettingsIcon,
  Palette,
  Atom,
  File,
  Image,
  Globe,
  WholeWord,
} from 'lucide-react';
import { useRecoilValue } from 'recoil';
import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import ClearConvos from './ClearConvos';
import Settings from './Settings';
import NavDropdown from './NavDropdown';
import NavLink from './NavLink';
import { ExportModel } from './ExportConversation';
import { DotsIcon } from '~/components';
import { useLocalize } from '~/hooks';
import SearchBar from './SearchBar';
// import { useAuthContext } from '~/hooks/AuthContext';
import { cn, stringToBoolean, cookie } from '~/utils/';
import global from '~/utils/global';
import store from '~/store';
// import ThemeIcon from '../svg/ThemeIcon';
const upgradeUrl = import.meta.env.VITE_UPGRADE_URL || 'https://start.ai-pro.org/upgrade';
// import Popup from '../ui/Popup';
// import { usePopup, subscriptionType, usePricingPopup } from '~/components/helper/store';
import { subscriptionType, usePricingPopup } from '~/components/helper/store';

export default function NavLinks({ clearSearch, isSearchEnabled, showThemeModal }) {
  const [showExports, setShowExports] = useState(false);
  const [showClearConvos, setShowClearConvos] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  // const { user } = useAuthContext();
  const localize = useLocalize();
  // const zState = usePopup((state) => state);
  const conversation = useRecoilValue(store.conversation) || {};
  const proState = subscriptionType((state) => state);

  const sState = usePricingPopup((state) => state);
  const exportable =
    conversation?.conversationId &&
    conversation?.conversationId !== 'new' &&
    conversation?.conversationId !== 'search';

  const clickHandler = () => {
    if (exportable) {
      setShowExports(true);
    }
  };

  const dreamPhotoLink = ((link) => {
    if (typeof AIPRO_USER !== 'undefined' && AIPRO_USER && AIPRO_USER.subscription_type) {
      const subscriptionType = AIPRO_USER.subscription_type;

      if (
        subscriptionType === 'BASIC' ||
        subscriptionType === 'PRO' ||
        subscriptionType === 'PROMAX' ||
        subscriptionType === 'ENTERPRISE'
      ) {
        return 'dream-photo';
      }
    }

    return 'text-to-image';
  })();

  const handleRedirectLink = (link) => {
    if (typeof AIPRO_USER !== 'undefined' && AIPRO_USER && AIPRO_USER.subscription_type) {
      const subscriptionType = AIPRO_USER.subscription_type;

      if (subscriptionType === 'BASIC') {
        return upgradeUrl;
      }
    }

    return link;
  };

  const onClickThemes = () => {
    if (proState.isPro) {
      showThemeModal();
    } else {
      global.showPricing(() => {
        sState.setShowPricingPopup(true);
      });
    }
  };

  const myAccountLink = () => {
    if (
      cookie.getValue('flow') === 'chatapp' &&
      cookie.getValue('n_flow') === 'chatapp' &&
      !cookie.getValue('user_plan')
    ) {
      return `https://${prefix}ai-pro.org/pricing-redirect/?from=chatapp`;
    }
    return `https://${prefix}start.ai-pro.org/my-account`;
  };

  const prefix = (() => {
    const currentLocation = window.location.href;

    let prefix = '';

    if (currentLocation.indexOf('staging') > -1) {
      prefix = 'staging.';
    } else if (currentLocation.indexOf('dev') > -1) {
      prefix = 'dev.';
    } else if (currentLocation.indexOf('localhost') > -1) {
      prefix = 'staging.';
    }

    return prefix;
  })();

  const navClickHandler = () => {
    const faq_page_link = import.meta.env.VITE_FAQ_PAGE;
    const open_to_new_tab = stringToBoolean(import.meta.env.VITE_FAQ_PAGE_OPEN_TO_NEW_TAB);

    if (faq_page_link) {
      if (open_to_new_tab) {
        window.open(faq_page_link, '_blank');
      } else {
        window.location.href = faq_page_link;
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const nav = document.querySelector('.nav');

      if (nav) {
        setMaxHeight(nav.clientHeight);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Menu as="div" className="group relative">
        {({ open }) => (
          <>
            <Menu.Button
              className={cn(
                'nav-menu group-ui-open:bg-gray-800 flex w-full items-center gap-2.5 rounded-md px-3 py-3 text-sm transition-colors duration-200 hover:bg-gray-800',
                open ? 'bg-gray-800' : '',
              )}
              role="button"
              aria-label="Menu"
            >
              <div className="grow overflow-hidden text-ellipsis whitespace-nowrap text-left text-white">
                Menu
              </div>
              <DotsIcon />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="nav-menu-items absolute bottom-full left-0 z-20 mb-2 h-auto w-full translate-y-0 overflow-hidden overflow-y-auto rounded-md bg-[#050509] py-1.5 opacity-100 outline-none"
                style={{
                  maxHeight: maxHeight > 0 ? maxHeight - 120 + 'px' : 'calc(100vh - 120px)',
                }}
              >
                {isSearchEnabled && (
                  <Menu.Item>
                    <SearchBar clearSearch={clearSearch} />
                  </Menu.Item>
                )}
                <Menu.Item as="div">
                  <NavLink
                    className={cn(
                      'nav-menu-link flex w-full cursor-pointer items-center gap-3 rounded-none px-3 py-3 text-sm text-white transition-colors duration-200 hover:bg-gray-700',
                      exportable ? 'cursor-pointer text-white' : 'cursor-not-allowed text-gray-400',
                    )}
                    role="button"
                    aria-label="Export Conversation"
                    svg={() => <Download size={16} role="img" aria-label="Export Conversation" />}
                    text={localize('com_nav_export_conversation')}
                    clickHandler={clickHandler}
                  />
                </Menu.Item>
                <div className="my-1.5 h-px bg-white/20" role="none" />
                <NavDropdown
                  dropdownOpen={dropdownOpen}
                  setDropdownOpen={setDropdownOpen}
                  svg={<Atom size={16} role="img" aria-label="Advanced Tools" />}
                  text="Advanced Tools"
                >
                  <Menu.Item as="div">
                    <NavLink
                      className="nav-menu-link group flex w-full cursor-pointer items-center gap-3 rounded-none px-3 py-3 text-sm text-white transition-colors duration-200 hover:bg-gray-700"
                      role="button"
                      aria-label="ChatPDF"
                      svg={() => (
                        <File
                          size={16}
                          role="img"
                          aria-label="ChatPDF"
                          className="rounded-full bg-red-800 text-white ring-[5px] ring-red-800 group-hover:text-white"
                        />
                      )}
                      text="ChatPDF"
                      href={handleRedirectLink(`https://${prefix}chatpdf.ai-pro.org/`)}
                      target="_blank"
                    />
                  </Menu.Item>
                  <Menu.Item as="div">
                    <NavLink
                      className="nav-menu-link flex w-full cursor-pointer items-center gap-3 rounded-none px-3 py-3 text-sm text-white transition-colors duration-200 hover:bg-gray-700"
                      role="button"
                      aria-label="Grammar AI"
                      svg={() => (
                        <WholeWord
                          size={16}
                          role="img"
                          aria-label="Grammar AI"
                          className="rounded-full bg-blue-800 text-white ring-[5px] ring-blue-800 group-hover:text-white"
                        />
                      )}
                      text="Grammar AI"
                      href={handleRedirectLink(
                        `https://${prefix}app.ai-pro.org/convert-to-proper-english`,
                      )}
                      target="_blank"
                    />
                  </Menu.Item>
                  <Menu.Item as="div">
                    <NavLink
                      className="nav-menu-link flex w-full cursor-pointer items-center gap-3 rounded-none px-3 py-3 text-sm text-white transition-colors duration-200 hover:bg-gray-700"
                      role="button"
                      aria-label="DreamPhoto"
                      svg={() => (
                        <Image
                          size={16}
                          role="img"
                          aria-label="DreamPhoto"
                          className="rounded-full bg-green-800 text-white ring-[5px] ring-green-800 group-hover:text-white"
                        />
                      )}
                      text="DreamPhoto"
                      href={`https://${prefix}app.ai-pro.org/${dreamPhotoLink}`}
                      target="_blank"
                    />
                  </Menu.Item>
                  <Menu.Item as="div">
                    <NavLink
                      className="nav-menu-link flex w-full cursor-pointer items-center gap-3 rounded-none px-3 py-3 text-sm text-white transition-colors duration-200 hover:bg-gray-700"
                      role="button"
                      aria-label="Search AI"
                      svg={() => (
                        <Globe
                          size={16}
                          role="img"
                          aria-label="Search AI"
                          className="rounded-full bg-yellow-800 text-white ring-[5px] ring-yellow-800 group-hover:text-white"
                        />
                      )}
                      text="Search AI"
                      href={handleRedirectLink(`https://${prefix}search.ai-pro.org/`)}
                      target="_blank"
                    />
                  </Menu.Item>
                  <Menu.Item as="div">
                    <NavLink
                      className="nav-menu-link flex w-full cursor-pointer items-center gap-3 rounded-none px-3 py-3 text-sm text-white transition-colors duration-200 hover:bg-gray-700"
                      role="button"
                      aria-label="See All 19 Tools"
                      svg={() => (
                        <LayoutPanelLeft size={16} role="img" aria-label="See All 19 Tools" />
                      )}
                      text="See All 19 Tools"
                      href={myAccountLink()}
                      target="_blank"
                    />
                  </Menu.Item>
                </NavDropdown>
                <div className="my-1.5 h-px bg-white/20" role="none" />
                {!proState.isPro && (
                  <Menu.Item as="div">
                    <NavLink
                      className="nav-menu-link flex w-full cursor-pointer items-center gap-3 rounded-none px-3 py-3 text-sm text-white transition-colors duration-200 hover:bg-gray-700"
                      svg={() => <Sparkles size={16} role="img" aria-label="Upgrade to PRO" />}
                      text="Upgrade to PRO"
                      clickHandler={() => {
                        global.showPricing(() => {
                          sState.setShowPricingPopup(true);
                        });
                      }}
                      role="button"
                      aria-label="Upgrade to PRO"
                    />
                  </Menu.Item>
                )}
                {import.meta.env.VITE_APPEARANCE_PREFERENCES == 'themes' && (
                  <Menu.Item as="div">
                    <NavLink
                      className="nav-menu-link flex w-full cursor-pointer items-center gap-3 rounded-none px-3 py-3 text-sm text-white transition-colors duration-200 hover:bg-gray-700"
                      svg={() => <Palette size={16} role="img" aria-label="Themes" />}
                      text={localize('com_nav_themes')}
                      pro={!proState.isPro}
                      clickHandler={onClickThemes}
                      role="button"
                      aria-label="Themes"
                    ></NavLink>
                  </Menu.Item>
                )}
                <Menu.Item as="div">
                  <NavLink
                    className="nav-menu-link flex w-full cursor-pointer items-center gap-3 rounded-none px-3 py-3 text-sm text-white transition-colors duration-200 hover:bg-gray-700"
                    svg={() => <HelpCircle size={16} role="img" aria-label="Help & FAQ" />}
                    text={localize('com_nav_help_faq')}
                    clickHandler={navClickHandler}
                    role="link"
                    aria-label="Help & FAQ"
                  />
                </Menu.Item>
                <Menu.Item as="div">
                  <NavLink
                    className="nav-menu-link flex w-full cursor-pointer items-center gap-3 rounded-none px-3 py-3 text-sm text-white transition-colors duration-200 hover:bg-gray-700"
                    svg={() => <SettingsIcon size={16} role="img" aria-label="Settings" />}
                    text={localize('com_nav_settings')}
                    clickHandler={() => setShowSettings(true)}
                    role="button"
                    aria-label="Settings"
                  />
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      {showExports && <ExportModel open={showExports} onOpenChange={setShowExports} />}
      {showClearConvos && <ClearConvos open={showClearConvos} onOpenChange={setShowClearConvos} />}
      {showSettings && <Settings open={showSettings} onOpenChange={setShowSettings} />}
    </>
  );
}
